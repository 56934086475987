import { useContext, useEffect, useState } from "react";

import { Button } from "@emisgroup/ui-button";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import DialogComponent, { IDialogProps } from "../../CustomComponents/DialogComponent/Index";
import { DialogContext, DialogContextType } from "../../../Contexts/DialogContext/DialogContext";

import "./DismissAllButton.scss";

export const DismissAllButton = (props) => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const [isDismissPopUpOpen, setIsDismissPopUpOpen] = useState(false);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "confirmation",
    onClose: () => {}
  });
  const { setIsDialogOpen } = useContext<DialogContextType>(DialogContext);

  useEffect(() => {
    setIsDialogOpen(isDismissPopUpOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDismissPopUpOpen]);

  const HandleDismissAllClick = () => {
    setIsDismissPopUpOpen(true);
  };

  const HandleDismissSaveClick = () => {
    DismissAllNotification();
  };

  const DismissAllNotification = () => {
    const deleteAllNotificationRequestProps: IRequestProps = {
      session,
      method: "DELETE",
      url: "/notifications"
    };

    setIsDismissPopUpOpen(false);
    HttpRequest(deleteAllNotificationRequestProps)
      .then(() => {
        props.setNotificationListData();
      })
      .catch(() => {
        setIsNotificationOpen(true);
        HandleApiError();
      });
  };

  const HandleApiError = () => {
    setNotificationProps({
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => setIsNotificationOpen(false)
    });
  };

  const dialogProps: IDialogProps = {
    dialogHeader: "Dismiss Notification",
    dialogContent: "Are you sure you want to dismiss all notifications?",
    isDialogOpen: isDismissPopUpOpen,
    setIsDialogOpen: setIsDismissPopUpOpen,
    handleSaveButtonClick: HandleDismissSaveClick,
    dialogFooterButtonSaveText: "Dismiss"
  };

  return (
    <>
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      <div className="systemtray-notification-dismissAll-button-container">
        <Button
          className="systemtray-notification-dismissAll-button"
          data-dd-action-name="systemtray-notification-dismissAll"
          id="notification-card-dismissAll-button"
          data-testid="notification-card-dismissAll-button"
          onClick={() => {
            HandleDismissAllClick();
          }}
        >
          Dismiss All
        </Button>
      </div>
      {isDismissPopUpOpen && <DialogComponent {...dialogProps} />}
    </>
  );
};
