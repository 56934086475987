import { useContext, useState } from "react";

import { Button } from "@emisgroup/ui-button";
import NotificationComponent, { INotificationProps } from "../../CustomComponents/NotificationComponent/Index";
import {
  IModuleTemplate,
  ModuleTemplateContext,
  ModuleTemplateContextValue
} from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import { DispatchModuleTemplateEvent, ModuleTemplateEnum } from "../../../Utilities/ModuleTemplate/Index";

export const SettingsButton = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);

  const HandleSettingslick = () => {
    const selectedTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === ModuleTemplateEnum.APPLICATION_SETTINGS;
    });

    if (selectedTemplate == null) {
      setIsNotificationOpen(true);
      return;
    }

    DispatchModuleTemplateEvent(selectedTemplate.attributes.module);
  };
  const errorNotificationProps: INotificationProps = {
    notificationType: "error",
    isOpen: isNotificationOpen,
    onClose: () => setIsNotificationOpen(false)
  };
  return (
    <>
      {isNotificationOpen && <NotificationComponent {...errorNotificationProps} />}
      <div className="settings-button-container">
        <Button
          data-dd-action-name="settings"
          id="settings-button"
          data-testid="settings-button"
          variant="filled"
          onClick={HandleSettingslick}
        >
          Settings
        </Button>
      </div>
    </>
  );
};
