import { useContext, useState } from "react";

import { ListItem } from "@emisgroup/ui-list";
import { AddLineBreaks } from "../../../Utilities/AddLineBreaks";
import { Tooltip } from "react-tooltip";
import { INotificationData } from "./NotificationCard";
import ModuleTemplateEnum from "../../../Utilities/ModuleTemplate/ModuleTemplateEnum";
import { DispatchModuleTemplateEvent, IModuleTemplate } from "../../../Utilities/ModuleTemplate/ModuleTemplate";
import { today } from "../../../Utilities/DateHelper";
import NotificationComponent, {
  INotificationProps,
  NotificationContent
} from "../../CustomComponents/NotificationComponent/Index";
import { ModuleTemplateContext, ModuleTemplateContextValue } from "../../../Contexts/ModuleTemplate/ModuleTemplate";
import HttpRequest, { IRequestProps } from "../../../Utilities/ApiHelper/HttpRequest";
import { SessionContext, SessionContextValue } from "../../../Contexts/Session/SessionContext";
import { useValidateNotificationItems } from "../../../CustomHooks/UseValidateNotificationItems";
import { INotificationPayloadType } from "./INotificationPaylaodType";
import { NotificationActionType } from "../../../Enum/NotificationActionTypeEnum";
import { Count } from "@emisgroup/ui-count";

type Props = {
  notificationData: INotificationData[];
  setFilteredNotificationList?: (
    id: number,
    isError: boolean,
    systemTrayPaylaodData: INotificationPayloadType,
    isNotificationActioned: boolean
  ) => void;
};

export const NotificationDataList: React.FC<Props> = (props) => {
  const { moduleTemplate } = useContext<ModuleTemplateContextValue>(ModuleTemplateContext);
  let filteredNotificationData = useValidateNotificationItems(props.notificationData);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { session } = useContext<SessionContextValue>(SessionContext);
  const [notificationProps, setNotificationProps] = useState<INotificationProps>({
    content: NotificationContent.default,
    isOpen: isNotificationOpen,
    notificationType: "confirmation",
    onClose: () => {}
  });

  const HandleDismissOnClick = async (notificationId: number) => {
    const notificationData = filteredNotificationData.filter((n) => n.id == notificationId);
    let payloadData: INotificationPayloadType = {
      SystemTrayNotificationActionType: NotificationActionType.DELETE,
      SystemTrayNotificationData: notificationData,
      HasError: false
    };

    const updateNotificationRequestProps: IRequestProps = {
      session,
      method: "DELETE",
      url: "/notifications",
      params: {
        notificationId
      }
    };

    await HttpRequest(updateNotificationRequestProps)
      .then(() => {
        props.setFilteredNotificationList(notificationId, false, payloadData, true);
      })
      .catch(() => {
        props.setFilteredNotificationList(notificationId, true, payloadData, true);
        setIsNotificationOpen(true);
        HandleApiError();
      });
  };

  const HandleApiError = () => {
    setNotificationProps({
      content: NotificationContent.error,
      isOpen: true,
      notificationType: "error",
      onClose: () => setIsNotificationOpen(false)
    });
  };

  const TriggerNotificationModule = (e: any, id: number) => {
    const selectedModuleTemplate = moduleTemplate.find((item: IModuleTemplate) => {
      return item.attributes.name === e.target.value;
    });
    if (selectedModuleTemplate == null) {
      setIsNotificationOpen(true);
      setNotificationProps({
        content: NotificationContent.error,
        isOpen: true,
        notificationType: "error",
        onClose: () => {
          setIsNotificationOpen(false);
        }
      });
      return;
    }

    let todayDateReplacement = {
      "##DATE##": JSON.stringify(today)
    };

    e.target.value === ModuleTemplateEnum.OWINGS
      ? DispatchModuleTemplateEvent(selectedModuleTemplate.attributes.module)
      : DispatchModuleTemplateEvent(selectedModuleTemplate.attributes.module, todayDateReplacement);
    HandleDismissOnClick(id);
  };

  return (
    <>
      {isNotificationOpen && <NotificationComponent {...notificationProps} />}
      {filteredNotificationData?.map(
        ({ id, typeName, count, snoozeFreeTime }) =>
          (snoozeFreeTime == null || new Date(snoozeFreeTime) <= new Date()) && (
            <div key={id}>
              <ListItem className="notification-list" data-testid="notification-list" key={id}>
                <div
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={AddLineBreaks(typeName)}
                  className="widget-list-item-content"
                >
                  <input
                    data-dd-action-name={typeName}
                    type="button"
                    data-testid={`notification-list-item-${id}`}
                    value={typeName}
                    tabIndex={0}
                    onClick={(e) => {
                      TriggerNotificationModule(e, id);
                    }}
                    id={`list-${typeName}`}
                    disabled={count <= 0}
                    className={"widget-list-item-text"}
                  />
                  <span className={"widget-count-badge"}>
                    <Count value={count} className={"widget-count-value"} />
                  </span>
                </div>
                <Tooltip id="my-tooltip" className="tooltip" />
              </ListItem>
              <hr className="widget-list-item-line" />
            </div>
          )
      )}
    </>
  );
};
